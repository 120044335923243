<template>
  <menu-lateral :auto-folded="false" :folded="true" default-class="menu-lateral-black">
    <div class="menu-container">
      <div class="">
        <h3 class="hide-folded">SLConfig</h3>

        <component v-if="erplayout.$dynamicMenuLeilaoComponent" v-bind:is="erplayout.$dynamicMenuLeilaoComponent.name"
                   :parse-props="erplayout.$dynamicMenuLeilaoComponent.props"
                   class="menu-leilao-dynamic-component"></component>

        <div class="m-t">
          <ul class="menu-leilao-items">
            <li v-for="m in menu" :class="[m.class || null, m.desc && 'desc', {'active': m.id === active}]">
              <span v-if="m.desc">{{ m.name }}</span>
              <a @click="link(m)" class="flex" :data-title="m.name" v-else>
                <div class="erp-icon-size" v-if="m.iconType === 'erp'"><i class="erp-icon" :class="m.icon"></i></div>
                <u-icon :name="m.icon" :type="m.iconType" :icon-style="m.iconStyle" v-else/>
                <span class="menu-lbl">{{ m.name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="help m-b-lg">
        <ul class="menu-leilao-items">
          <router-link tag="li" :to="{name: 'usuarios.main'}" active-class="active" class="item-min">
            <a class="flex" data-title="Usuários internos">
              <u-icon name="users" type="fa" icon-style="light" class="orange"/>
              <span class="menu-lbl font-11">Usuários internos</span>
            </a>
          </router-link>
          <router-link tag="li" :to="{name: 'sites.lista'}" active-class="active" class="item-min">
            <a class="flex" data-title="Sites">
              <u-icon name="sitemap" type="fa" icon-style="light" class="orange2"/>
              <span class="menu-lbl font-11">Sites</span>
            </a>
          </router-link>
          <router-link tag="li" :to="{name: 'importador.main'}" active-class="active" class="item-min">
            <a class="flex" data-title="Importação e Backup">
              <u-icon name="file-import" type="fa" icon-style="light"/>
              <span class="menu-lbl font-11">Importação e Backup</span>
            </a>
          </router-link>
          <li class="m-t-md">
            <a class="flex" data-title="Ajuda">
              <u-icon name="question-square" type="fa"/>
              <span class="menu-lbl">Ajuda</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </menu-lateral>
</template>

<script>
import MenuJS from './menu-config'
import MenuLateral from '../../../layout/MenuLateral'

export default {
  name: 'MenuConfig',
  components: {MenuLateral},
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  props: ['active'],
  mounted() {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 400)
  },
  beforeDestroy() {
    this.erplayout.erpheader.setMenuModuleToggle(false)
  },
  methods: {
    link(m) {
      console.log('Link', m)
      if (m.route) {
        this.$router.push({name: m.route})
      }
    }
  },
  computed: {
    menu() {
      return MenuJS
    }
  }
}
</script>
