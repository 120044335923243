export default [
  {
    id: 'basic',
    name: 'Cadastro Básico',
    icon: 'database',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'cadastro.main'
  },
  {
    id: 'templates',
    name: 'Templates e Documentos',
    icon: 'file-alt',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'configuracoes.templates'
  },
  {
    id: 'variaveis',
    name: 'Variáveis',
    icon: 'file-signature',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'configuracoes.variaveis'
  },
  {
    id: 'taxas',
    name: 'Impostos e Taxas',
    icon: 'badge-percent',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'configuracoes.taxas'
  },
  {
    id: 'sistema',
    name: 'Sistema',
    icon: 'cogs',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'configuracoesglobais.main'
  }
]
